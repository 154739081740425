import React from "react"
import { Link } from "gatsby"
import './BlogListItem.scss';

const BlogListItem = (props) => {

  const { post, date } = props;
  const title = post.frontmatter.title || post.fields.slug;
  const isRecent = (new Date(post.frontmatter.date) - new Date()) / 24 / 60 / 60 / 1000 > -7;

  return (
    <Link to={post.fields.slug} className="list-group-item list-group-item-action border-0">
      <div className="d-flex w-auto">
        <p className="mb-0">{title}</p>
        {isRecent ? <span className="text-primary handwritten ml-3">NEW</span> : null}
        {date ? <small className="text-muted ml-auto">{post.frontmatter.date}</small> : null}
      </div>
    </Link>
  )
}

export default BlogListItem;
