import React from 'react';
import { Link } from 'gatsby';
import './BlogList.scss';
import BlogListItem from '../BlogListItem';

class BlogList extends React.Component {

    render() {
        const { title, link, posts } = this.props;

        return (
            <section className="BlogList">
                <h3 className="d-flex align-items-center">
                    <span>{title}</span>
                    <Link to={link} className="btn btn-secondary btn-xs ml-auto">View All</Link>
                </h3>
                <div className="list-group">
                  {posts.map(({ node }) => <BlogListItem key={node.fields.slug} post={node} />)}
                </div>
            </section>
        )
    }
}

export default BlogList;