import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import './Bio.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
            instagram
            github
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata;

  return (
    <section className="Bio">
      <div className="card">
        <div className="card-body">
          <div className="d-flex flex-column">
            <p className="mb-0">
              I'm a full-stack software engineer and coding bootcamp instructor in San
              Francisco. This is where I write about the things that I'm both teaching others
              and re/learning myself.
            </p>
            <div className="text-right">
              <a className="icon" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/${social.twitter}`}>
                <FontAwesomeIcon width="0" icon={['fab', 'twitter']} />
              </a>
              {`   `}
              <a className="icon" target="_blank" rel="noopener noreferrer" href={`https://instagram.com/${social.instagram}`}>
                <FontAwesomeIcon width="0" icon={['fab', 'instagram']} />
              </a>
              {`   `}
              <a className="icon" target="_blank" rel="noopener noreferrer" href={`https://github.com/${social.github}`}>
                <FontAwesomeIcon width="0" icon={['fab', 'github']} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-5" />
    </section>
  )
}

export default Bio;
